<template>
  <v-card id="fullBox">
    <div id="messages">
      <v-card-text class="grow" id="growingChat">
        <div id="chatbox">
          <p class="botText">
            <img src="../assets/images/BOB.png" alt="Bot Picture" style="width:30px;height:30px;position:relative;top:12px;">
            <span id="greeting"></span>
          </p>
        </div>
<!--        <p v-html="text" v-for="(text, index) in chats" :key="index" class="text-wrap">
          {{ text }}
        </p>-->
      </v-card-text>
    </div>
    <v-card-actions id="userMsg" class="userMsgBox">
      <v-row dense>
        <v-form @submit.prevent="sendMessage(currentMsg)" id="row">
          <v-text-field v-model="currentMsg">
            <template v-slot:append-outer>
              <v-btn id="send-button" type="submit"><font-awesome-icon icon="fas fa-paper-plane" /></v-btn>
            </template>
          </v-text-field>
        </v-form>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      currentMsg: "",
      chats: ["UMD Virtual Assistant : Welcome"],
      token: "",
      client: null,
      conversation: null,
      username: "anonymous",
      from: "UMD Virtual Assistant",
      bot: 0,
      genUserId: null,
    };
  },
  methods: {
    greeting(){
      let greet = document.getElementById("greeting");
      if (this.$route.query.botName == "ditBusinessOffice") {
        greet.innerHTML = "Hello, I'm BOB the Business Office Bot! What can I help you with today?";
      }
      else if (this.$route.query.botName == "Bursar") {
        greet.innerHTML = "Hello, I'm the Student Financial Services & Cashiering virtual assistant! I can answer general questions regarding student billing.";
        this.messageAdded("You can use key phrases like 'billing information' or 'payment plan'. What can I help you with today?",true);
      }
      else if (this.$route.query.botName == "healLine") {
        greet.innerHTML = "Hello, I'm the Heal Line virtual assistant! I can answer general questions regarding Covid-19 policies and UHC services."
        this.messageAdded("For specific information regarding your personal health, please contact the health center at 301-314-8184 or " +
            "visit <a href='https://www.myuhc.umd.edu/home.aspx' target='_blank'>myuhc.umd.edu</a>.",true);
        this.messageAdded("If you are having a medical emergency, please dial 911. What can I help you with today?",true);
      }
    },
    sizing(){
      if(this.$route.query.botName == "Bursar"){
        document.getElementById("fullBox").style.height = "385px";
        document.getElementById("messages").style.height = "385px";
      }
      else if(this.$route.query.size != "large"){
        document.getElementById("fullBox").style.height = "425px";
      }
      if(this.$route.query.size == "large"){
        document.getElementById("messages").style.height = "300px";
      }
    },
    async sendMessage(msg) {
      this.currentMsg = "";
      let cleanMsg = msg.replace(/[^a-z0-9?!., :]/gi, '');
      await this.messageAdded(cleanMsg,false);
      await this.getMessage(cleanMsg);
    },
    async messageAdded(msg,is_VA) {
      let messages = document.getElementById("messages");
      let chatbox = document.getElementById("chatbox");
      let growingChat = document.getElementById("growingChat");
      this.bot = is_VA ? this.bot : growingChat.clientHeight;
      let addHTML = is_VA ?
          '<p class="botText">' +
            '<img src="../BOB.png" alt="Bot Picture" ' +
            'style="width:30px;height:30px;position:relative;top:12px;">' +
            '<span>' + msg + '</span></p>' :
          '<p class="userText"><span>' + msg + '</span></p>';
      chatbox.insertAdjacentHTML("beforeend", addHTML);
      messages.scrollTop = this.bot - 30;
    },
    async getMessage(msg) {
      console.log(this.$route.query.botName);
      let response = await this.$axios({
        url: "https://5yn0xyf0i3.execute-api.us-east-1.amazonaws.com/api/chat",
        method: 'post',
        baseURL: "",
        data: {
          userId: this.genUserId,
          message: msg,
          botName: this.$route.query.botName
        },
      });
      console.log(response);
      let res = response.data.queryResult.fulfillmentText;
      await this.messageAdded(res,true);
    },
  },
  mounted(){
    this.greeting();
    this.sizing();
    this.genUserId = this.$uuidv4();
  },
};
</script>
<style>
html, body {
  overflow-y: hidden;
}
#send-button {
  font-family: interstate, sans-serif;
}
#fullBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

#messages {
  overflow-y: auto;
  overflow-x: auto;
  height: 425px;
}

#userMsg {
  border-top: 3px solid #dedede;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

#growingChat {
  font-family: interstate, sans-serif;
}
p {
  margin-bottom: 16px;
}

#row {
  width: 100%;
  font-family: interstate, sans-serif;
}

.userMsgBox {
  height: 65px;
  background-color: white;
  border-bottom-left-radius: 8px;
}

.userText {
  color: white;
  font-size: 14px;
  font-weight: normal;
  text-align: right;
  clear: both;
}

.userText span {
  line-height: 1.5em;
  display: inline-block;
  background: #406770;
  padding: 10px;
  border-radius: 8px;
  border-bottom-right-radius: 2px;
  max-width: 80%;
  margin-right: 10px;
  animation: floatup .5s forwards
}

.botText {
  color: #000;
  font-weight: normal;
  font-size: 14px;
  text-align: left;
}

.botText span {
  line-height: 1.5em;
  display: inline-block;
  background: #E9EDF1;
  padding: 10px;
  border-radius: 8px;
  border-bottom-left-radius: 2px;
  max-width: 80%;
  margin-left: 10px;
  animation: floatup .5s forwards
}
</style>